<template>
  <div>
    <b-form @submit.stop.prevent="formOnsubmit">
      <!-- Input Medicine -->
      <!-- <b-form-group
      id="input-group-medicine"
      label="Obat:"
      label-for="input-medicine"
    >
      <treeselect v-model="form.medicine_id" :multiple="false" :options="medicine" />
      <small class="text-danger">{{ error.medicine_id }}</small>
    </b-form-group> -->

      <!-- Input Stock In Out -->
      <!-- <b-form-group
        id="input-group-stock-in-out"
        label="Jenis Stok:"
        label-for="input-stock-in-out"
      >
        <b-form-select v-model="form.stock_in_out" :options="options"></b-form-select>
        <small class="text-danger">{{ error.stock_in_out }}</small>
      </b-form-group> -->

      <!-- Input Quantity -->
      <!-- <b-form-group
      id="input-group-quantity"
      label="Kuantitas:"
      label-for="input-quantity"
    >
      <b-form-input
        id="input-quantity"
        v-model="form.quantity"
        type="number"
        placeholder="Kuantitas"
      >
      </b-form-input>
      <small class="text-danger">{{ error.quantity }}</small>
    </b-form-group> -->

      <!-- Input Remaining Stock -->
      <!-- <b-form-group
      id="input-group-remaining-stock"
      label="Stok Sisa:"
      label-for="input-remaining-stock"
    >
      <b-form-input
        id="input-remaining-stock"
        v-model="form.remaining_stock"
        type="number"
        placeholder="Stok Sisa"
      >
      </b-form-input>
      <small class="text-danger">{{ error.remaining_stock }}</small>
    </b-form-group> -->

      <!-- Input Price -->
        <b-form-group
          id="input-group-price"
          label="Harga:"
          label-for="input-price"
        >
          <b-form-input
            id="input-price"
            v-model="formatedPrice"
            placeholder="Harga"
          >
          </b-form-input>
          <small class="text-danger">{{ error.price }}</small>
        </b-form-group>

      <!-- Input Company -->
      <b-form-group
        id="input-group-vendor"
        label="Penyedia:"
        label-for="input-vendor"
      >
        <treeselect
          v-model="form.vendor_id"
          :multiple="false"
          :options="vendors"
        />
        <small class="text-danger">{{ error.vendor_id }}</small>
      </b-form-group>

      <!-- Input Batch No -->
      <b-form-group
        id="input-group-batch-no"
        label="Batch No:"
        label-for="input-batch-no"
      >
        <b-form-input
          id="input-batch-no"
          v-model="form.batch_no"
          type="text"
          placeholder="Batch No"
        >
        </b-form-input>
        <small class="text-danger">{{ error.batch_no }}</small>
      </b-form-group>

      <!-- Input Expired -->
      <b-form-group
        id="input-group-expired"
        label="Tanggal Kadaluarsa:"
        label-for="input-expired"
      >
        <b-form-datepicker
          locale="id"
          id="example-datepicker"
          v-model="form.expired"
          class="mb-2"
        ></b-form-datepicker>
        <small class="text-danger">{{ error.expired }}</small>
      </b-form-group>

      <!-- Input Description -->
      <b-form-group
        id="input-group-notes"
        label="Catatan:"
        label-for="input-notes"
      >
        <b-form-textarea
          id="input-notes"
          v-model="form.notes"
          placeholder="Catatan"
          rows="4"
          max-rows="6"
        ></b-form-textarea>
        <small class="text-danger">{{ error.notes }}</small>
      </b-form-group>

      <!-- Button -->
      <b-button
        type="submit"
        variant="primary"
      >Simpan</b-button>
      <b-button
        class="ml-2"
        variant="default"
        @click="$router.push('/medicine/maintain-stock')"
      >
        Batal
      </b-button>
    </b-form>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import validation from '@/core/modules/ValidationModule.js'
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "Add",

  props: {
    form: Object,
    route: String
  },

  components: {
    Card,
  },

  data() {
    return {
      medicine: [],
      vendors: [],
      // Error
      error: {
        medicine_id: null,
        medicine_name: "",
        stock_in_out: null,
        quantity: null,
        remaining_stock: null,
        price: null,
        vendor_id: null,
        vendor_name: null,
        batch_no: "",
        expired: "",
        notes: "",
      },
      options: [
        { value: null, text: 'Pilih Jeni Stok' },
        { value: 0, text: 'Stok Masuk' },
        { value: 1, text: 'Stok Keluar' },
      ],
      route_vendor: "",
      form_vendor: {
        medicine_id: this.form.medicine_id,
        vendor_id: this.form.vendor_id,
      }
    };
  },

  methods: {
    async getMedicineOption() {
      let response = await module.setTreeSelect("medicines");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.medicine = response.data;
      }
    },
    async getVendorOption() {
      let response = await module.setTreeSelect("vendors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.vendors = response.data;
      }
    },

    async formOnsubmit() {
      let response = await module.submit(this.form, this.route);

      if (response.state == "error") {
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/medicine/maintain-stock");
      }
    },

  },

  computed: {
    formatedPrice: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ''), 10)
        if (!Number.isNaN(newValue)) {
          this.form.price = newValue.toLocaleString('id-ID')
        } else {
          this.form.price = ''
        }
      },
      get() {
        return this.form.price
      }
    }
  },

  mounted() {
    this.getMedicineOption();
    this.getVendorOption();
  },
};
</script>

<style>
</style>